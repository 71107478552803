
import { eoRendererMixin, metaDataMixin, dateFormatMixin } from '~/mixins'

export default {
  mixins: [
    eoRendererMixin,
    dateFormatMixin,
    metaDataMixin,
  ],
  async asyncData ({ $config, $api, $link, store, route, error, redirect, params: { slug }, query: { utm_campaign = '' } }) {
    const {
      data: {
        items: articles = [],
      } = {},
    } = await $api.articles.getOneBySlug(slug , {
      domain: `${$config.preprEnvironmentDomainEo},${$config.preprEnvironmentDomainEva},${$config.preprEnvironmentDomainLazarus},${$config.preprEnvironmentDomainVisie}`,
      utm_campaign,
    })

    if (!articles || !articles.length) {
      error({
        statusCode: 404,
        message: '(404) Artikel niet gevonden',
        context: `Couldn't find publication: ${slug}.`,
        severity: 'info',
      })
      return false
    }

    const article = articles[0]

    // Render articles of Ter Apel on another route
    // Prepr somehow doesn't send the slug of the Story, so we also check the name as a backup
    if (article.stories && article.stories.some(story => story.slug === 'gezichten-van-ter-apel' || story.name === 'Gezichten van Ter Apel')) {
      redirect(`/gezichten-van-ter-apel/${slug}`)
      return
    }

    // Render articles of Zorgmedewerker van het jaar on another route
    if (article.campaign && article.campaign.slug === 'zorgmedewerker-van-het-jaar') {
      redirect(`/campagnes/zorgmedewerker-van-het-jaar/genomineerden/${slug}`)
      return
    }

    store.dispatch('breadcrumbs/set', {
      path: route.path,
      width: 6,
      eoRoot: true,
      items: [
        ...[
          article.program
          ? {
              to: $link.publication(article.program),
              label: article.program.title,
            }
          : article.topic && {
            to: `/thema/${article.topic.slug}`,
            label: article.topic.name,
          },
        ],
        {
          label: article.title,
        },
      ],
    })

    if (article.environmentId == $config.preprEnvironmentIdEva) {
      // Set analytics project to Eva
      store.dispatch('pianoAnalytics/setProject', $config.trackingFieldEva)
    } else if (article.environmentId == $config.preprEnvironmentIdVisie) {
      // Set analytics project to Visie
      store.dispatch('pianoAnalytics/setProject', $config.trackingFieldVisie)
    } else {
      store.dispatch('pianoAnalytics/setProject', $config.trackingFieldHome)
    }

    return {
      ...article,
    }
  },
  data () {
    return {
      id: null,
      changedOn: null,
      publishOn: null,
      slug: null,
      readTime: null,
      modelId: null,
      modelName: null,
      environmentId: null,
      title: null,
      subtitle: null,
      featuredVideo: null,
      hero: null,
      intro: null,
      likes: null,
      content: [],
      seo: null,
      social: null,
      tags: null,
      stories: null,
      topic: null,
      program: null,
      podcast: null,
      community: null,
      author: null,
      type: null,
      location: null,
      relatedArticles: [],
      amountRelatedArticlesAnywhere: 0,
      amountRelatedArticlesCommunity: 0,
      liked: false,
      showTopicContent: false,
      topicNewsLetter: null,
      ctaStack: null,
    }
  },
  async fetch () {
    const communityPublication = this.$library.prepr.environments[this.environmentId]?.publicationId
    const communitySlug = this.$library.prepr.environments[this.environmentId]?.slug

    // Get data about community from Prepr
    if (communityPublication && communitySlug) {
      const {
        data: {
          item: community = {},
        } = {},
      } = await this.$api.communities.getOne(communityPublication)

      if (!community) {
        this.$log.info('No community found for:', { communityPublication })
      }
      this.community = community

    }
  },
  computed: {
    isCommunityEva () {
      return this.community?.slug === 'eva'
    },
    isCommunityVisie () {
      return this.community?.slug === 'visie'
    },
    isCommunityLazarus () {
      return this.community?.slug === 'lazarus'
    },
    isAuthorExternal () {
      return this.author?.role?.some((role) => {
        return role.slug === 'gastauteur'
      })
    },
    labels () {
      const labels = []

      if (this.topic) {
        labels.push({
          slug: this.topic.slug,
          name: this.topic.name,
          to: `/thema/${this.topic.slug}`,
          icon: 'spirit',
          textColor: 'purple-700',
        })
      }

      if (this.stories && this.stories.length) {
        this.stories.forEach((story) => {
          labels.push({
            slug: story.slug,
            name: story.name,
            to: `/rubriek/${story.slug}`,
          })
        })
      }

      if (this.tags && this.tags.length) {
        this.tags.forEach((tag) => {
          labels.push({
            slug: tag.slug,
            name: tag.name,
            to: `/alles-over/${tag.slug}`,
          })
        })
      }
      return labels
    },
    totalLikes () {
      // Because the Article Like Endpoint is cached we need to fake the likes in the front-end
      return this.likes + (this.liked ? 1 : 0)
    },
    likeLabel () {
      if (this.liked && this.totalLikes === 1) {
        return "Jij gaf dit een hartje"
      } else if (this.liked && this.totalLikes === 2) {
        return `Jij en ${this.likes} ander gaven een hartje`
      } else if (this.liked && this.totalLikes > 2) {
        return `Jij en ${this.likes} anderen gaven een hartje`
      } else if (!this.liked && this.likes === 1) {
        return `${this.likes} ander gaf een hartje`
      } else if (!this.liked && this.likes > 1 ) {
        return `${this.likes} anderen gaven een hartje`
      } else {
        return `Geef een hartje`
      }
    },
    shareFacebookUrl () {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.$config.baseUrlCanonical + this.$route.path}`
    },
    shareWhatsAppUrl () {
      return `https://api.whatsapp.com/send?text=${this.$config.baseUrlCanonical + this.$route.path}`
    },
  },
  methods: {
    async getRelatedArticles(ratio) {
      if (this.relatedArticles.length) return
      if (ratio <= 0) return
      if (!this.id) return

      // From all of EO
      const {
        data: {
          items: rArticles = [],
        } = {},
      } = await this.$api.recommendations.getSimilar(
        {
          id: this.id,
          resource: 'articles',
          topic: this.topic?.id,
          publishAfter: "2021-01-01T00:00Z",
          limit: 6,
        },
      )
      if (!rArticles || !rArticles.length) {
        this.$log.info('No related articles found for:', { id: this.id })
      }

      this.relatedArticles = rArticles

      // reset values
      this.amountRelatedArticlesCommunity = 0
      this.amountRelatedArticlesAnywhere = 0

      // Note community of first 6 items
      this.relatedArticles.forEach(article => {
        if (this.$library.prepr.environments[ article.environmentId ]?.slug === this.$config.preprEnvironmentDomain) {
          this.amountRelatedArticlesCommunity += 1
        } else {
          this.amountRelatedArticlesAnywhere += 1
        }
      })
    },
    async getTopicContent () {
      if (!this.topic || !this.topic.id) return
      const {
        data: {
          item: topicResult = {},
        },
      } = await this.$api.topics.getOne(this.topic.id)

      const { content, ...rest } = topicResult

      // Split Topic Content and Topic Newsletter (newsletter is always the last item)
      this.topic = {
        content: content.slice(0, content.length - 1),
        ...rest,
      }

      if (content[content.length - 1]) {
        this.topicNewsLetter = content[content.length - 1].items
      }

      if (topicResult) {
        this.showTopicContent = !this.isCommunityEva && !this.isCommunityVisie && this.topic && this.topic.content
      }
    },

    onShare (method) {
      // Send event to Prepr
      window.prepr('event', 'Share', { constraint: true })

      // Send event to GTM
      if (this.$gtm) {
        this.$gtm.push({
          event: 'eo_share',
          'method': method,
          'content_type': 'article',
          'item_id': this.id,
        })
      }
    },
  },
  jsonld () {
    return {
      '@context': 'http://schema.org',
      '@type': 'Article',
      datePublished: this.publishOn,
      dateModified: this.changeOn,
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': this.$config.baseUrlCanonical + this.$route.path,
      },
      headline: this.seo?.title,
      description: this.seo?.description,
      image: this.hero,
      author: {
        '@type': 'Person',
        name: this.author?.name,
        // @todo: add image of author (if it is available in first call)
        // @todo: add url of author detailpage as 'mainEntityOfPage'
        // @todo: add url of author detailpage as 'sameAs'
      },
      publisher: {
        '@type': 'Organization',
        name: 'EO',
        logo: {
          '@type': 'ImageObject',
          url: this.$config.baseUrlCanonical + require('~/assets/logo/evangelische-omroep.png'),
        },
      },
    }
  },
}
